<template>
  <div
    class="d-flex justify-center gap pt-2 my-0"
    style="background-color: #00a9f4"
  >
    <div
      class="tab tab-button text-center cursor-pointer pt-2"
      @click="$emit('gis-info-button-clicked', 0)"
      v-ripple
      id="tasksBtn"
    >
      <div>
        <v-badge
          color="orange"
          :content="tasksLength"
          overlap
          :value="tasksLength > 0"
        >
          <v-icon color="white">{{ mdiFormatListChecks }}</v-icon>
        </v-badge>
      </div>
      <p class="white--text">Tasks</p>
    </div>
    <div
      class="tab tab-button text-center cursor-pointer pt-2"
      @click="$emit('gis-info-button-clicked', 1)"
      v-ripple
      id="logBtn"
    >
      <v-icon color="white">{{ mdiViewList }}</v-icon>
      <p class="white--text">Log</p>
    </div>
    <div
      class="tab tab-button text-center cursor-pointer pt-2"
      @click="$emit('gis-info-button-clicked', 2)"
      v-ripple
      id="docsBtn"
    >
      <v-icon color="white">{{ mdiFile }}</v-icon>
      <p class="white--text">Docs</p>
    </div>
  </div>
</template>

<script>
import {
  mdiCheckboxMarkedOutline,
  mdiFormatListChecks,
  mdiViewList,
  mdiFile,
} from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "FeatureItemGisInfoButtonBar",
  data() {
    return {
      mdiCheckboxMarkedOutline,
      mdiFormatListChecks,
      mdiViewList,
      mdiFile,
      actionItems: [],
      tasks: [],
    };
  },
  props: {
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    taskCount: Number,
  },
  computed: {
    tasksLength() {
      return this.tasks.length;
    },
  },
  methods: {
    async getTasks() {
      if (!this.selectedMapServiceId || !this.objectId) {
        return;
      }
      this.tasks = await axiosWithRegularAuth.get(
        `${APIURL}/action_item_and_tasks`,
        {
          params: {
            map_service_id: this.selectedMapServiceId,
            object_id: this.objectId,
          },
        }
      );

      this.tasks = this.tasks.data.results.filter(
        (t) =>
          t.status.toLowerCase() !== "closed" &&
          t.status.toLowerCase() !== "canceled" &&
          t.status.toLowerCase() !== "cancelled"
      );
    },
  },
  beforeMount() {
    this.getTasks();
  },
  watch: {
    globalId() {
      this.getTasks();
    },
    objectId() {
      this.getTasks();
    },
    selectedMapServiceId() {
      this.getTasks();
    },
  },
};
</script>

<style scoped>
.tab-button {
  width: 33%;
}
</style>
