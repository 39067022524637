<template>
  <v-card flat color="#3F51B5" width="100%" class="rounded-0">
    <v-list color="#3F51B5" class="my-0 py-1" width="100%">
      <v-list-item>
        <v-list-item-content class="py-0 my-0" two-line>
          <section
            class="
              d-flex
              justify-space-between
              align-center
              flex-grow-0
              overflow-hidden
            "
          >
            <div
              class="overflow-hidden flex-grow-1"
              v-if="selectedMapService && selectedMapService.site_enabled"
            >
              <SharedSiteEditorForm
                :title="title"
                :selectedMapServiceId="selectedMapServiceId"
                :objectId="objectId"
                :globalId="globalId"
                :sharedSite="sharedSite"
                @edited-site="$emit('edited-site')"
              />
            </div>
            <div class="overflow-hidden flex-grow-1" v-else>
              <div
                class="d-flex justify-space-between my-2 overflow-hidden"
                style="font-size: 12px"
              >
                <div class="white--text overflow-hidden text-truncate">
                  {{ title }}
                </div>
              </div>

              <div class="white--text overflow-hidden text-truncate">
                {{ subtitle }}
              </div>
            </div>

            <div class="d-flex flex-grow-0">
              <v-btn
                icon
                text
                @click="$emit('open-expand-panel-dialog')"
                style="font-size: 12px"
                id="expandPanelBtn"
              >
                <v-icon color="white">
                  {{ mdiArrowExpand }}
                </v-icon>
              </v-btn>

              <v-btn
                icon
                text
                @click="$emit('gis-info-panel-close')"
                style="font-size: 12px"
              >
                <v-icon color="white">
                  {{ mdiClose }}
                </v-icon>
              </v-btn>
            </div>
          </section>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import {
  mdiClose,
  mdiChevronLeft,
  mdiChevronRight,
  mdiArrowExpand,
} from "@mdi/js";
import { db } from "@/mixins/utilisync-db";
import SharedSiteEditorForm from "@/components/shared/SharedSiteEditorForm";

export default {
  name: "FeatureItemGisInfoTopCard",
  props: {
    selectedGisInfo: Object,
    selectedMapServiceId: String,
    objectId: Number,
    attributes: Object,
    sharedSite: Object,
    globalId: String,
  },
  components: { SharedSiteEditorForm },
  computed: {
    title() {
      return this.selectedMapService?.service_name;
    },
    subtitle() {
      return (
        this.attributes?.[this.selectedMapService?.ref_field] ??
        "[Not Provided]"
      );
    },
  },
  data() {
    return {
      mdiClose,
      mdiChevronLeft,
      mdiChevronRight,
      mdiArrowExpand,
      selectedMapService: {},
      gisDataFieldsAndValues: [],
    };
  },
  methods: {
    async getMapService() {
      const [mapService] = await db.mapServices
        .filter((m) => {
          return m.map_service_id === this.selectedMapServiceId;
        })
        .toArray();
      this.selectedMapService = mapService;
    },
  },
  async beforeMount() {
    await this.getMapService();
  },
  watch: {
    async objectId() {
      await this.getMapService();
    },
  },
};
</script>
